body {
  max-width: 750px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

.old-result {
  text-decoration: line-through;
}

.container {
  padding: 1rem;
}

.old-result-title {
  color: red;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.order-result-title {
  font-weight: 600;
  margin-top: 0.5rem;
}

.order-names {
  padding-left: 2rem;
}

.footer-elements {
  padding: 0.2rem;
}

button {
  padding: 0.4rem 1.5rem;
  margin-right: 1rem;
}

.ordinal {
  display: inline-block;
  min-width: 2.3rem;
}
